import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×8\\@75% 1RM`}</p>
    <p>{`DB Bent Side Laterals 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`50-Calorie Row, 25-T2B, 10-S2OH (135/95, RX+ 155/105)`}</p>
    <p>{`40-Calorie Row, 20-T2B, 8-S2OH`}</p>
    <p>{`30-Calorie Row, 15-T2B, 6-S2OH`}</p>
    <p>{`20-Calorie Row, 10-T2B, 4-S2OH`}</p>
    <p>{`10-Calorie Row, 5-T2B, 2-S2OH`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There is an American Heart Association walk this Saturday which
starts at 9:00am and goes down Main street so you may want to arrive
early for class and park on Washington, Market, Clay, or Shelby
Street.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      